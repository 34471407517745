export default [
    {
        path: '/',
        name: 'login',
        component: () => import('../views/login')
    },
    {
        path: '/index',
        name: 'index',
        component: () => import('../views/index')
    },
    {
        path: '/register',
        name: 'register',
        // component: () => import('../views/register')
        component: () => import('../views/iterateRegister/iterateRegister.vue')
    },
    {
        path: '/signature',
        name: 'signature',
        // component: () => import('../views/signature')
        component: () => import('../views/iterateRegister/signature.vue')
    },
    {
        path: '/paySuccess',
        name: 'paySuccess',
        // component: () => import('../views/signature')
        component: () => import('../views/paySuccess.vue')
    }
];
