import Cookies from 'js-cookie';
export const TOKEN_KEY = 'token';
export const USERID_KEY = 'userID';
export const COOKIE_EXPIRES = 30;

function setToken(token) {
    Cookies.set(TOKEN_KEY, token, {
        expires: COOKIE_EXPIRES || 1
    });
}

function getToken() {
    const token = Cookies.get(TOKEN_KEY);
    if (token !== 'false') return token;
    else return false;
}

function setUserID(userID) {
    Cookies.set(USERID_KEY, userID, {
        expires: COOKIE_EXPIRES || 1
    });
}

function getUserID() {
    const userID = Cookies.get(USERID_KEY);
    if (userID !== 'false') return userID;
    else return 0;
}

export default {
    state: {
        token: getToken(),
        userID: getUserID(),
        userInfo: null
    },
    getters: {
        token: (state) => state.token,
        userInfo: (state) => state.userInfo,
        userID: (state) => state.userID
    },
    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token;
            setToken(token);
        },
        SET_USERID: (state, userID) => {
            state.userID = userID;
            setUserID(userID);
        },
        SET_INFO: (state, userInfo) => {
            state.userInfo = userInfo;
        }
    }
};
