import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Vant, { Lazyload, Swipe, SwipeItem } from 'vant';
import 'vant/lib/index.css';
import '@/assets/font/font.css';
import './util/rem';
// 滚动
import scroll from 'vue-seamless-scroll';
// vue-amap 高德地图
import AmapVue from '@amap/amap-vue';
// element-ui 组件库
import ElementUI from 'element-ui';
import { Message } from 'element-ui';
Vue.prototype.$message = Message;
import 'element-ui/lib/theme-chalk/index.css';
// pubsub-js 组件消息订阅
import PubSub from 'pubsub-js';
import dataV from '@jiaminghi/data-view';
// 弹幕
import { vueBaberrage } from 'vue-baberrage';
import moment from 'moment';

moment.locale('zh-cn', { weekdays: '周日_周一_周二_周三_周四_周五_周六'.split('_') });

window._AMapSecurityConfig = {
    securityJsCode: 'd6d1111d945e0d7bccd9527396dcda2e'
};
AmapVue.config.version = '2.0'; // 默认2.0，这里可以不修改
AmapVue.config.key = 'fc17edcd0c96c36735dcbdfb94d696e5';
AmapVue.config.plugins = [
    'AMap.ToolBar',
    'AMap.MoveAnimation',
    'MarkerClusterer'
    // 在此配置你需要预加载的插件，如果不配置，在使用到的时候会自动异步加载
];

Vue.use(AmapVue);
Vue.use(Vant);
Vue.use(Lazyload);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(scroll);
Vue.use(vueBaberrage);
Vue.use(dataV);
Vue.use(scroll);
Vue.use(ElementUI, { zIndex: 3000 });
Vue.use(PubSub);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: (h) => h(App)
}).$mount('#app');
