<template>
    <div id="app" class="">
        <div class="app position-relative">
            <router-view />
        </div>
    </div>
</template>
<script>
export default {
    name: 'Prevention',
    components: {},
    data() {
        return {};
    },
    watch: {},
    mounted() {},
    created() {},
    methods: {}
};
</script>
<style>
@import 'assets/css/common.css';
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: white;
    width: 100%;
    height: auto;
    /* max-width: 1920px; */
    min-height: 100vh;
    overflow-x: hidden;
}
</style>
