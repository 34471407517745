import Router from 'vue-router';
import Vue from 'vue';
import system from './system';
import { Toast } from 'vant';
// import store from '@/store';
// import { RefreshToken } from '@/util/login';
Vue.use(Router);

const router = new Router({
    base: process.env.NODE_ENV === 'production' ? '/' : '/',
    routes: system
});

const loadingToast = Toast.loading({
    overlay: true,
    message: '加载中...'
});

router.beforeEach(async (to, from, next) => {
    loadingToast.open();
    // console.log(from.path + ' route to ' + to.path);
    if (to.path === from.path) {
        next(false);
    }
    next();
    // if (to.name === "cover") {
    //   next()
    //   return
    // } else {
    //   let token = store.state.user.token
    //   if (token) {
    //     console.log(token)
    //     let res = await RefreshToken()
    //     if (res.errcode === 0) {
    //       store.commit('SET_TOKEN', res.datas.Token)
    //       store.commit('SET_USERID', res.datas.ID)
    //       next()
    //     } else {
    //       this.$message.error('暂无权限');
    //     }
    //   } else {
    //     this.$message.error('暂无权限');
    //   }
    // }
});

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
    loadingToast.clear();
});
export default router;
